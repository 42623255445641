import React from 'react';
import { Stack } from '@mui/material';

import ChallengeCreator from '../../components/ChallengeCreator/ChallengeCreator';

interface Props {
	currentNetwork: string;
	setNetwork: React.Dispatch<React.SetStateAction<string>>;
}

export default function Builder({ currentNetwork, setNetwork }: Props) {
	return (
		<Stack
			component="main"
			justifyContent="center"
			alignItems="center"
			spacing="2rem"
			padding="2rem">
			<ChallengeCreator currentNetwork={currentNetwork} setNetwork={setNetwork} />
		</Stack>
	);
}
