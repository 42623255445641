import { MetaMaskInpageProvider } from '@metamask/providers';
import { providers } from 'ethers';

export let provider: providers.Web3Provider;

declare global {
	export interface Window {
		ethereum?: MetaMaskInpageProvider;
	}
}

export async function createMetaMaskProvider(
	provider: providers.ExternalProvider | MetaMaskInpageProvider,
): Promise<providers.Web3Provider> {
	return new providers.Web3Provider(provider as providers.ExternalProvider);
}

export const switchNetwork = async (targetNetworkId: providers.Networkish): Promise<boolean> => {
	let chainId: string;
	if (!window.ethereum) return false;

	try {
		chainId =
			providers.getNetwork(targetNetworkId).chainId !== (await provider.getNetwork()).chainId
				? `0x${Number(providers.getNetwork(targetNetworkId).chainId).toString(16)}`
				: `0x${Number((await provider.getNetwork()).chainId).toString(16)}`;
	} catch (error: unknown) {
		console.error(
			'TypeError: Wrong chainId - enter either a number like 5 (goerli network) or a string like rinkeby',
		);
		return false;
	}

	try {
		await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: chainId }],
		});
	} catch (error: unknown) {
		console.log(error);
		return false;
	}

	return true;
};

export async function connectToMetaMask(): Promise<string[]> {
	if (!window.ethereum) return [''];

	provider = await createMetaMaskProvider(window.ethereum);

	try {
		const accounts = (await window.ethereum.request({ method: 'eth_requestAccounts' })) as string[];
		return accounts;
	} catch (error: unknown) {
		try {
			const accounts = (await window.ethereum.request({
				method: 'wallet_requestPermissions',
				params: [{ eth_accounts: {} }],
			})) as string[];
			return accounts;
		} catch (error) {
			console.log('please, connect to metamask');
			return [];
		}
	}
}
