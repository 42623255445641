import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface DisciplinePickerProps {
	label: string;
	selectLabels: string[];
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
}

export default function DisciplinePicker({
	label,
	selectLabels,
	value,
	setValue,
}: DisciplinePickerProps) {
	const handleChange = (event: SelectChangeEvent) => {
		setValue(event.target.value as string);
	};

	return (
		<FormControl fullWidth required>
			<InputLabel id="select-label">{label}</InputLabel>
			<Select labelId="select-label" value={value} label={label} onChange={handleChange}>
				{selectLabels.map((item, index) => (
					<MenuItem key={index} value={index}>
						{item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
