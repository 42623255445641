import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import BuildIcon from '@mui/icons-material/Build';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import {
	Routes, //replaces "Switch" used till v5
	Route,
} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { connectToMetaMask } from '../../services/web3/providerHandler';
import Builder from '../../pages/Builder/Builder';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Settings from '../../pages/Settings/Settings';
import logo from '../../assets/gym-wallet-white.svg';

const drawerWidth = 240;
const footerHeight = 60;

export default function ResponsiveDrawer() {
	const navigate = useNavigate();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [currentNetwork, setNetwork] = React.useState('11155111');
	const [currentAddress, setAddress] = React.useState('');

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const getIcon = (index: number) => {
		switch (index) {
			case 0:
				return <BuildIcon />;
			case 1:
				return <DashboardIcon />;
			case 2:
				return <SettingsIcon />;
		}
	};

	const navigateWithDrawer = async (index: number) => {
		switch (index) {
			case 0:
				navigate('/');
				break;

			case 1: {
				if (!window.ethereum) return;
				const address = (await connectToMetaMask())[0];
				if (address) {
					setAddress(address);
					navigate('/dashboard');
				}

				break;
			}

			case 2:
				navigate('/settings');
				break;

			default:
				break;
		}
	};

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				{['Builder', 'Dashboard', 'Settings'].map((text, index) => (
					<ListItem key={text} disablePadding>
						<ListItemButton onClick={() => navigateWithDrawer(index)}>
							<ListItemIcon>{getIcon(index)}</ListItemIcon>
							<ListItemText primary={text} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</div>
	);

	const container = window !== undefined ? () => window.document.body : undefined;

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					height: '64px',
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
				}}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}>
						<MenuIcon />
					</IconButton>
					<img src={logo} alt="" style={{ padding: '0.8rem', height: '100%' }} />
				</Toolbar>
			</AppBar>
			<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					open>
					{drawer}
				</Drawer>
			</Box>
			<Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
				<Toolbar />
				<Box component="article" sx={{ padding: '1rem' }}>
					<Routes>
						<Route
							path="/"
							element={<Builder currentNetwork={currentNetwork} setNetwork={setNetwork} />}
						/>
						<Route
							path="/dashboard"
							element={
								<Dashboard currentAddress={currentAddress} currentNetwork={currentNetwork} />
							}
						/>
						<Route path="/settings" element={<Settings />} />
					</Routes>
				</Box>
				<Paper
					component="footer"
					sx={{
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						padding: '1rem 0',
						height: footerHeight,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'fixed',
						bottom: 0,
					}}>
					<Typography>Copyright &copy;2022 Gymnasia Labs.</Typography>
				</Paper>
			</Box>
		</Box>
	);
}
