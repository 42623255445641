import { createTheme, ThemeOptions } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// import '@fontsource/istok-web/300.css';
import '@fontsource/istok-web/400.css';
// import '@fontsource/istok-web/500.css';
import '@fontsource/istok-web/700.css';

const themeOptions: ThemeOptions = {
	palette: {
		mode: 'dark',
		primary: {
			main: '#000000',
			light: '#2c2c2c',
			dark: '#000000',
		},
		secondary: {
			main: '#006a72',
			light: '#4698a0',
			dark: '#003f47',
		},
	},
	typography: {
		fontFamily: ['Istok Web', 'Roboto', 'sans-serif'].join(','),
		h1: {
			fontSize: '1.2rem',
		},
		button: {
			fontWeight: 700,
		},
	},
	shape: {
		borderRadius: 5,
	},
	transitions: {
		duration: {
			shortest: 150,
			shorter: 200,
			short: 250,
			// most basic recommended timing
			standard: 300,
			// this is to be used in complex animations
			complex: 375,
			// recommended when something is entering screen
			enteringScreen: 225,
			// recommended when something is leaving screen
			leavingScreen: 195,
		},
		easing: {
			// This is the most common easing curve.
			easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
			// Objects enter the screen at full velocity from off-screen and
			// slowly decelerate to a resting point.
			easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
			// Objects leave the screen at full velocity. They do not decelerate when off-screen.
			easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
			// The sharp curve is used by objects that may return to the screen at any time.
			sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
		},
	},
};

export const theme = createTheme(themeOptions);
