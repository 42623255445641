import { ContractInterface } from './contractInterface';

export const sepolia: ContractInterface = {
	challengeManagerAddress: '0x762F27E5c3dACe71AA6B5737E96Cdd88690eE52e',
	challengeManagerAbi: [
		{
			inputs: [
				{
					internalType: 'address',
					name: 'gymnasiaAddress',
					type: 'address',
				},
				{
					internalType: 'address',
					name: 'apiAddress',
					type: 'address',
				},
			],
			stateMutability: 'nonpayable',
			type: 'constructor',
		},
		{
			anonymous: false,
			inputs: [
				{
					indexed: true,
					internalType: 'address',
					name: 'previousOwner',
					type: 'address',
				},
				{
					indexed: true,
					internalType: 'address',
					name: 'newOwner',
					type: 'address',
				},
			],
			name: 'OwnershipTransferred',
			type: 'event',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			name: '_challenges',
			outputs: [
				{
					internalType: 'uint256',
					name: 'id',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'creator',
					type: 'address',
				},
				{
					internalType: 'uint256',
					name: 'start',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'end',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'workouttype',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'condition',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'currentParticipantsCount',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'maxParticipantsCount',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'submissionFee',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'prizePool',
					type: 'uint256',
				},
				{
					internalType: 'bool',
					name: 'redeemed',
					type: 'bool',
				},
				{
					internalType: 'enum ChallengeManager.Gender',
					name: 'gender',
					type: 'uint8',
				},
				{
					internalType: 'bool',
					name: 'multiSubmitAllowed',
					type: 'bool',
				},
				{
					internalType: 'address',
					name: 'evaluation',
					type: 'address',
				},
				{
					internalType: 'bool',
					name: 'allowLiveTicketPurchase',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			name: '_prefunder',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [],
			name: 'owner',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'address',
					name: 'newOwner',
					type: 'address',
				},
			],
			name: 'transferOwnership',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [],
			name: 'getAddress',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [],
			name: 'getGymnasiaAddress',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'address',
					name: 'adr',
					type: 'address',
				},
			],
			name: 'setGymnasiaAddress',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'amountInWei',
					type: 'uint256',
				},
			],
			name: 'setMinSubmissionFee',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint8',
					name: 'percentage',
					type: 'uint8',
				},
			],
			name: 'setGymnasiaFee',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'timeInSeconds',
					type: 'uint256',
				},
			],
			name: 'setBufferTime',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'getSubmissionFee',
			outputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					components: [
						{
							internalType: 'uint256',
							name: 'id',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'creator',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'start',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'end',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'workouttype',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'condition',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'currentParticipantsCount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'maxParticipantsCount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'submissionFee',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'prizePool',
							type: 'uint256',
						},
						{
							internalType: 'bool',
							name: 'redeemed',
							type: 'bool',
						},
						{
							internalType: 'enum ChallengeManager.Gender',
							name: 'gender',
							type: 'uint8',
						},
						{
							internalType: 'bool',
							name: 'multiSubmitAllowed',
							type: 'bool',
						},
						{
							internalType: 'address',
							name: 'evaluation',
							type: 'address',
						},
						{
							internalType: 'bool',
							name: 'allowLiveTicketPurchase',
							type: 'bool',
						},
					],
					internalType: 'struct ChallengeManager.Challenge',
					name: 'challenge',
					type: 'tuple',
				},
			],
			name: 'createChallenge',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'prefundChallenge',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					components: [
						{
							internalType: 'uint256',
							name: 'challengeId',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'athleteAddress',
							type: 'address',
						},
						{
							internalType: 'uint32',
							name: 'value',
							type: 'uint32',
						},
					],
					internalType: 'struct ChallengeManager.Result[]',
					name: 'results',
					type: 'tuple[]',
				},
			],
			name: 'submitData',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'buyTicket',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'refund',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'isBuyTicketAllowed',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'athleteAddress',
					type: 'address',
				},
			],
			name: 'hasUnlockedChallenge',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'withdraw',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'address',
					name: 'athleteAddress',
					type: 'address',
				},
			],
			name: 'getChallenges',
			outputs: [
				{
					components: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'id',
									type: 'uint256',
								},
								{
									internalType: 'address',
									name: 'creator',
									type: 'address',
								},
								{
									internalType: 'uint256',
									name: 'start',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'end',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'workouttype',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'condition',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'currentParticipantsCount',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'maxParticipantsCount',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'submissionFee',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'prizePool',
									type: 'uint256',
								},
								{
									internalType: 'bool',
									name: 'redeemed',
									type: 'bool',
								},
								{
									internalType: 'enum ChallengeManager.Gender',
									name: 'gender',
									type: 'uint8',
								},
								{
									internalType: 'bool',
									name: 'multiSubmitAllowed',
									type: 'bool',
								},
								{
									internalType: 'address',
									name: 'evaluation',
									type: 'address',
								},
								{
									internalType: 'bool',
									name: 'allowLiveTicketPurchase',
									type: 'bool',
								},
							],
							internalType: 'struct ChallengeManager.Challenge',
							name: 'challenge',
							type: 'tuple',
						},
						{
							internalType: 'bool',
							name: 'hasUnlocked',
							type: 'bool',
						},
					],
					internalType: 'struct ChallengeManager.UnlockedChallenge[]',
					name: '',
					type: 'tuple[]',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'getLeaderboard',
			outputs: [
				{
					components: [
						{
							internalType: 'address',
							name: 'athleteAddress',
							type: 'address',
						},
						{
							internalType: 'uint32',
							name: 'value',
							type: 'uint32',
						},
					],
					internalType: 'struct ChallengeManager.LeaderboardItem[]',
					name: '',
					type: 'tuple[]',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'athleteAddress',
					type: 'address',
				},
			],
			name: 'hasChallengeSpaceForAthlete',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'isChallengeExisting',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'hasChallengeStarted',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'hasChallengeNotEnded',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
	],
	verifierAddress: '0x38161d8B7E5332DCE6f80e6318543f1988A7230D',
	verifierAbi: [
		{
			inputs: [
				{
					internalType: 'string',
					name: 'message',
					type: 'string',
				},
				{
					internalType: 'uint8',
					name: 'v',
					type: 'uint8',
				},
				{
					internalType: 'bytes32',
					name: 'r',
					type: 'bytes32',
				},
				{
					internalType: 'bytes32',
					name: 's',
					type: 'bytes32',
				},
			],
			name: 'verifyString',
			outputs: [
				{
					internalType: 'address',
					name: 'signer',
					type: 'address',
				},
			],
			stateMutability: 'pure',
			type: 'function',
			constant: true,
		},
	],
	OneWinnerMinEvaluationAddress: '0x3E6FbAE0D3BDBbe4b43Aa2f15cb0f7A0EA703E80',
	OneWinnerMaxEvaluationAddress: '0x3233B32A9aFa3c3D9ABD758913aa89027528f99E',
	AllWinnerMaxEvaluationAddress: '0xe83bAc49725aafC119f72E83d6571403e05D5c34',
	AllWinnerMaxEvaluationAbi: [
		{
			inputs: [
				{
					internalType: 'address[]',
					name: 'athleteAddresses',
					type: 'address[]',
				},
			],
			name: 'evaluate',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'prizePool',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'participantsCount',
					type: 'uint256',
				},
			],
			name: 'getCurrentPayouts',
			outputs: [
				{
					internalType: 'int256[]',
					name: '',
					type: 'int256[]',
				},
			],
			stateMutability: 'pure',
			type: 'function',
			constant: true,
		},
	],
};
