import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { provider, switchNetwork } from '../../services/web3/providerHandler';
import { contracts } from '../../services/web3/contracts/contracts';
import { ethers } from 'ethers';
import { Stack } from '@mui/system';

interface Props {
	currentNetwork: string;
	currentAddress: string;
}

interface LeaderboardEntry {
	athleteAddress: string;
	value: number;
}

interface UnlockedChallengeType {
	challenge: ChallengeType;
	hasUnlocked: boolean;
}

interface ChallengeType {
	id: number;
	creator: string;
	start: Moment;
	end: Moment;
	workouttype: number;
	condition: number;
	currentParticipantsCount: number;
	maxParticipantsCount: number;
	submissionFee: number;
	prizePool: number;
	redeemed: boolean;
	gender: number;
	multiSubmitAllowed: boolean;
	leaderboard: LeaderboardEntry[];
}

export default function Dashboard({ currentNetwork, currentAddress }: Props) {
	const [currentChallenges, setChallenges] = useState<ChallengeType[]>([]);

	useEffect(() => {
		const getChallenges = async () => {
			await switchNetwork(+currentNetwork);

			const challengeManagerContract = new ethers.Contract(
				contracts[+currentNetwork].challengeManagerAddress,
				contracts[+currentNetwork].challengeManagerAbi,
				provider.getSigner(),
			);

			const unlockedChallenges: UnlockedChallengeType[] =
				await challengeManagerContract.getChallenges(currentAddress);

			if (unlockedChallenges.length === 1) {
				if (isNaN(unlockedChallenges[0].challenge.id)) return;
			}

			const leaderboards: any[] = [];
			for (let i = 0; i < unlockedChallenges.length; i++) {
				leaderboards.push(await challengeManagerContract.getLeaderboard(i));
			}

			setChallenges(
				unlockedChallenges.map((element, index) => {
					return {
						id: +element.challenge.id,
						creator: element.challenge.creator.toString(),
						start: moment.unix(+element.challenge.start),
						end: moment.unix(+element.challenge.end),
						workouttype: +element.challenge.workouttype,
						condition: +element.challenge.condition,
						currentParticipantsCount: +element.challenge.currentParticipantsCount,
						maxParticipantsCount: +element.challenge.maxParticipantsCount,
						submissionFee: +ethers.utils.formatEther(element.challenge.submissionFee),
						prizePool: +ethers.utils.formatEther(element.challenge.prizePool),
						redeemed: element.challenge.redeemed,
						gender: +element.challenge.gender,
						multiSubmitAllowed: element.challenge.multiSubmitAllowed,
						leaderboard: leaderboards[index],
					};
				}),
			);
		};
		// //todo just call one time -> useEffect is called two times
		getChallenges()
			.then(() => console.log('fetching challenges done'))
			.catch((error) => console.log(error));

		return () => {
			setChallenges([]);
		};
	}, []);

	function Leaderboard(props: { leaderboard: LeaderboardEntry[] }) {
		return (
			<Box>
				{props.leaderboard.map((element, index) => {
					return (
						<Box key={index}>
							<Typography>{element.athleteAddress}</Typography>
							<Typography>{element.value}</Typography>
						</Box>
					);
				})}
			</Box>
		);
	}

	function Challenge(props: { challenge: ChallengeType }) {
		return (
			<Stack direction={'row'} spacing={2}>
				<Box>
					<Typography>{props.challenge.id}</Typography>
					<Typography>{props.challenge.creator}</Typography>
					<Typography>{props.challenge.start.toString()}</Typography>
					<Typography>{props.challenge.end.toString()}</Typography>
					<Typography>{props.challenge.submissionFee}</Typography>
					<Typography>{props.challenge.prizePool}</Typography>
					<Typography>{props.challenge.currentParticipantsCount}</Typography>
					<Typography>{props.challenge.maxParticipantsCount}</Typography>
				</Box>
				<Leaderboard leaderboard={props.challenge.leaderboard}></Leaderboard>
			</Stack>
		);
	}

	return (
		<>
			{currentChallenges.map((element, index) => (
				<Challenge key={index} challenge={element} />
			))}
		</>
	);
}
