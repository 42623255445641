import React, { useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	FormControlLabel,
	Switch,
	Typography,
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
	Alert,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import moment from 'moment';
import { BigNumber, ethers } from 'ethers';

import './ChallengeCreator.css';
import CustomSelect from '../CustomSelect/CustomSelect';
import { connectToMetaMask, provider, switchNetwork } from '../../services/web3/providerHandler';
import { contracts } from '../../services/web3/contracts/contracts';

interface Props {
	currentNetwork: string;
	setNetwork: React.Dispatch<React.SetStateAction<string>>;
}

export default function ChallengeCreator({ currentNetwork, setNetwork }: Props) {
	const [currentDiscipline, setDiscipline] = useState('0');
	const [currentCurrency, setCurrency] = useState('0');
	const [maxParticipantsCount, setMaxParticipantCount] = useState(100);
	const [registrationFee, setRegistrationFee] = useState(0.0001);
	const [startDateValue, setStartDateValue] = React.useState<moment.Moment>(
		moment().add(10, 'minutes'),
	);
	const [endDateValue, setEndDateValue] = React.useState<moment.Moment>(
		moment().add(1, 'day').add(10, 'minutes'),
	);
	const [allowLiveTicketPurchase, setAllowLiveTicketPurchase] = useState<boolean>(true);

	const handleChange = (
		newValue: moment.Moment,
		setValue: React.Dispatch<React.SetStateAction<moment.Moment>>,
	) => {
		setValue(newValue);
	};

	const createChallenge = async () => {
		if (!window.ethereum) return <Alert severity="error">Creation failed!</Alert>;
		const accounts = await connectToMetaMask();

		await switchNetwork(+currentNetwork);

		const challengeManagerContract = new ethers.Contract(
			contracts[+currentNetwork].challengeManagerAddress,
			contracts[+currentNetwork].challengeManagerAbi,
			provider.getSigner(),
		);

		console.log(`${Math.trunc(+startDateValue / 1000)} ${Math.trunc(+endDateValue / 1000)}`);

		const challenge = {
			id: ethers.constants.Zero._hex,
			creator: accounts[0],
			start: BigNumber.from(Math.trunc(+startDateValue / 1000)),
			end: BigNumber.from(Math.trunc(+endDateValue / 1000)),
			workouttype: ethers.constants.Zero._hex,
			condition: ethers.constants.Zero._hex,
			currentParticipantsCount: ethers.constants.Zero._hex,
			maxParticipantsCount: BigNumber.from(maxParticipantsCount),
			submissionFee: ethers.utils.parseEther(String(registrationFee))._hex,
			prizePool: ethers.constants.Zero._hex,
			first: ethers.constants.AddressZero,
			redeemed: false,
			gender: ethers.constants.Zero._hex,
			multiSubmitAllowed: false,
			evaluation: contracts[+currentNetwork].AllWinnerMaxEvaluationAddress,
			allowLiveTicketPurchase: allowLiveTicketPurchase,
		};

		await challengeManagerContract.createChallenge(challenge);
	};

	return (
		<Card className="card">
			<CardContent className="cardContent">
				<Box className="container">
					<Typography
						className="header"
						variant="h1"
						sx={{ letterSpacing: '1rem', fontWeight: '700', padding: '0rem' }}>
						BUILDER
					</Typography>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<MobileDateTimePicker
							renderInput={(props) => <TextField {...props} />}
							label="Start Date"
							value={startDateValue}
							onChange={(newValue) => {
								handleChange(newValue ? newValue : moment(), setStartDateValue);
							}}
						/>
						<MobileDateTimePicker
							renderInput={(props) => <TextField {...props} />}
							label="End Date"
							value={endDateValue}
							onChange={(newValue) => {
								handleChange(newValue ? newValue : moment(), setEndDateValue);
							}}
						/>
					</LocalizationProvider>
					<Divider className="divider"></Divider>
					<CustomSelect
						label="Discipline"
						selectLabels={['Row', 'Ski', 'Bike']}
						value={currentDiscipline}
						setValue={setDiscipline}
					/>
					{/* <TextField
						label="Meters"
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						defaultValue={100}
						required
					/> */}
					<Divider className="divider" />
					<TextField
						id={'label'}
						label="Registration Fee"
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						value={registrationFee}
						onChange={(newValue) => {
							setRegistrationFee(+newValue.target.value);
						}}
						required
					/>
					<CustomSelect
						label="Currency"
						selectLabels={['ETH', 'USDC']}
						value={currentCurrency}
						setValue={setCurrency}
					/>
					<TextField
						label="Participants"
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						value={maxParticipantsCount}
						onChange={(newValue) => {
							setMaxParticipantCount(+newValue.target.value);
						}}
						required
					/>
					<FormControl fullWidth required>
						<InputLabel id="select-network">Network</InputLabel>
						<Select
							value={currentNetwork}
							label="Network"
							labelId="select-network"
							onChange={(event) => setNetwork(event.target.value)}>
							{/* <MenuItem value={5}>Görli</MenuItem> */}
							<MenuItem value={11155111}>Sepolia</MenuItem>
						</Select>
					</FormControl>
					<FormControl fullWidth required>
						<FormControlLabel
							control={
								<Switch
									checked={allowLiveTicketPurchase}
									onChange={(newValue) => {
										setAllowLiveTicketPurchase(newValue.target.checked);
									}}
								/>
							}
							label="Live purchase allowed"
						/>
					</FormControl>
				</Box>
			</CardContent>
			<CardContent sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button onClick={createChallenge} variant="contained">
					Create
				</Button>
			</CardContent>
		</Card>
	);
}
