import { ContractInterface } from './contractInterface';

export const goerli: ContractInterface = {
	challengeManagerAddress: '0x9537Dc5CCC8B4a44F0FcFe57d9a7Fb5E2f3f7C7E',
	challengeManagerAbi: [
		{
			inputs: [
				{
					internalType: 'address',
					name: 'gymnasiaAddress',
					type: 'address',
				},
				{
					internalType: 'address',
					name: 'apiAddress',
					type: 'address',
				},
				{
					internalType: 'address',
					name: 'challengeAccount',
					type: 'address',
				},
			],
			stateMutability: 'nonpayable',
			type: 'constructor',
		},
		{
			anonymous: false,
			inputs: [
				{
					indexed: true,
					internalType: 'address',
					name: 'previousOwner',
					type: 'address',
				},
				{
					indexed: true,
					internalType: 'address',
					name: 'newOwner',
					type: 'address',
				},
			],
			name: 'OwnershipTransferred',
			type: 'event',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			name: 'athleteAddresses',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			name: 'challengeKeys',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			name: 'challenges',
			outputs: [
				{
					internalType: 'uint256',
					name: 'id',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'creator',
					type: 'address',
				},
				{
					internalType: 'uint256',
					name: 'start',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'end',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'workouttype',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'condition',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'currentParticipantsCount',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'maxParticipantsCount',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'submissionFee',
					type: 'uint256',
				},
				{
					internalType: 'uint256',
					name: 'prizePool',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'first',
					type: 'address',
				},
				{
					internalType: 'bool',
					name: 'redeemed',
					type: 'bool',
				},
				{
					internalType: 'enum ChallengeManager.Gender',
					name: 'gender',
					type: 'uint8',
				},
				{
					internalType: 'bool',
					name: 'multiSubmitAllowed',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			name: 'leaderboards',
			outputs: [
				{
					internalType: 'uint32',
					name: '',
					type: 'uint32',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [],
			name: 'owner',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			name: 'prefunder',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'address',
					name: 'newOwner',
					type: 'address',
				},
			],
			name: 'transferOwnership',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [],
			name: 'getGymnasiaAddress',
			outputs: [
				{
					internalType: 'address',
					name: '',
					type: 'address',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'address',
					name: 'adr',
					type: 'address',
				},
			],
			name: 'setGymnasiaAddress',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint8',
					name: 'percentage',
					type: 'uint8',
				},
			],
			name: 'setGymnasiaFee',
			outputs: [],
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'getSubmissionFee',
			outputs: [
				{
					internalType: 'uint256',
					name: '',
					type: 'uint256',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					components: [
						{
							internalType: 'uint256',
							name: 'id',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'creator',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'start',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'end',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'workouttype',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'condition',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'currentParticipantsCount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'maxParticipantsCount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'submissionFee',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'prizePool',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'first',
							type: 'address',
						},
						{
							internalType: 'bool',
							name: 'redeemed',
							type: 'bool',
						},
						{
							internalType: 'enum ChallengeManager.Gender',
							name: 'gender',
							type: 'uint8',
						},
						{
							internalType: 'bool',
							name: 'multiSubmitAllowed',
							type: 'bool',
						},
					],
					internalType: 'struct ChallengeManager.Challenge',
					name: 'challenge',
					type: 'tuple',
				},
			],
			name: 'createChallenge',
			outputs: [
				{
					components: [
						{
							internalType: 'uint256',
							name: 'id',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'creator',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'start',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'end',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'workouttype',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'condition',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'currentParticipantsCount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'maxParticipantsCount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'submissionFee',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'prizePool',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'first',
							type: 'address',
						},
						{
							internalType: 'bool',
							name: 'redeemed',
							type: 'bool',
						},
						{
							internalType: 'enum ChallengeManager.Gender',
							name: 'gender',
							type: 'uint8',
						},
						{
							internalType: 'bool',
							name: 'multiSubmitAllowed',
							type: 'bool',
						},
					],
					internalType: 'struct ChallengeManager.Challenge',
					name: '',
					type: 'tuple',
				},
			],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
			],
			name: 'prefundChallenge',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					components: [
						{
							internalType: 'uint256',
							name: 'challengeId',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'athleteAddress',
							type: 'address',
						},
						{
							internalType: 'uint32',
							name: 'value',
							type: 'uint32',
						},
					],
					internalType: 'struct ChallengeManager.Results[]',
					name: 'results',
					type: 'tuple[]',
				},
			],
			name: 'submitData',
			outputs: [],
			stateMutability: 'payable',
			type: 'function',
			payable: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'athleteAddresse',
					type: 'address',
				},
			],
			name: 'hasEnoughFundsForChallenge',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
		{
			inputs: [
				{
					internalType: 'uint256',
					name: 'challengeId',
					type: 'uint256',
				},
				{
					internalType: 'address',
					name: 'athleteAddress',
					type: 'address',
				},
			],
			name: 'hasUnlockedChallenge',
			outputs: [
				{
					internalType: 'bool',
					name: '',
					type: 'bool',
				},
			],
			stateMutability: 'view',
			type: 'function',
			constant: true,
		},
	],
	verifierAddress: '0x9FFA978Bd0f6e35653a7fd756CfbcfACa9952Ab7',
	verifierAbi: [
		{
			inputs: [
				{
					internalType: 'string',
					name: 'message',
					type: 'string',
				},
				{
					internalType: 'uint8',
					name: 'v',
					type: 'uint8',
				},
				{
					internalType: 'bytes32',
					name: 'r',
					type: 'bytes32',
				},
				{
					internalType: 'bytes32',
					name: 's',
					type: 'bytes32',
				},
			],
			name: 'verifyString',
			outputs: [
				{
					internalType: 'address',
					name: 'signer',
					type: 'address',
				},
			],
			stateMutability: 'pure',
			type: 'function',
			constant: true,
		},
	],
	OneWinnerMinEvaluationAddress: '0xB77c3546332e9838b0d6603A19F2C83c57831F0e',
	OneWinnerMaxEvaluationAddress: '0x9537Dc5CCC8B4a44F0FcFe57d9a7Fb5E2f3f7C7E',
	AllWinnerMaxEvaluationAddress: '0xc47E5Abf2321Ca1ba951fBdAc780562813f20082',
};
